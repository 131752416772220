
import { Component, Vue } from 'vue-property-decorator'
@Component
export default class Index extends Vue {
  created () {
    this.$nextTick(() => {
      const urlParams = new URLSearchParams(window.location.search)
      const uuid: string| null = urlParams.get('uuid')
      if (uuid) {
        const node: HTMLScriptElement = document.createElement('script')
        node.setAttribute('type', 'text/javascript')
        node.setAttribute('defer', 'true')
        node.setAttribute('async', 'true')
        node.setAttribute('data-chayall-account', uuid)
        node.setAttribute('src', `${process.env.VUE_APP_FRONT_URL}/js/chayall.js`)
        const elt: HTMLDivElement | undefined = this.$el as HTMLDivElement
        if (elt) {
          elt.appendChild(node)
        }
      }
    })
  }
}
